import javascript from "../../assets/skills/frontend/javascript.png";
import react from "../../assets/skills/frontend/react.svg";
import html from "../../assets/skills/frontend/html5.png";
import css from "../../assets/skills/frontend/css3.png";
import sass from "../../assets/skills/frontend/sass.svg";
import typescript from "../../assets/skills/frontend/typescript.png";
import jquery from "../../assets/skills/frontend/jquery.svg";
import greensock from "../../assets/skills/frontend/greensock.svg";
import jest from "../../assets/skills/frontend/jest.svg";
import node from "../../assets/skills/backend/nodejs.png";
import express from "../../assets/skills/backend/Express.png";
import sql from "../../assets/skills/backend/sql.png";
import mongo from "../../assets/skills/backend/mongo.png";
import docker from "../../assets/skills/devops/docker.png";
import kubernetes from "../../assets/skills/devops/kubernetes.png";
import heroku from "../../assets/skills/devops/heroku.svg";
import netlify from "../../assets/skills/devops/netlify.png";
import phpMyAdmin from "../../assets/skills/devops/phpmyadmin.png";
import cPanel from "../../assets/skills/devops/cpanel.png";
import figma from "../../assets/skills/other/figma.svg";
import office from "../../assets/skills/other/microsoft_office.svg";
import vscode from "../../assets/skills/other/vscode.png";
import photoshop from "../../assets/skills/other/photoshop.png";
import lightroom from "../../assets/skills/other/lightroom.png";
import npm from "../../assets/skills/other/npm.svg";
import insomnia from "../../assets/skills/other/insomnia.svg";
import slack from "../../assets/skills/other/slack.png";
import zoom from "../../assets/skills/other/zoom.png";
import githubLight from "../../assets/skills/other/githubLight.png";
import cypressWhite from "../../assets/skills/frontend/cypress-white.png";

export const skillsData = {
  frontend: [
    { name: "Javascript", image: javascript },
    { name: "React", image: react },
    { name: "HTML5", image: html },
    { name: "CSS3", image: css },
    { name: "SASS", image: sass },
    { name: "Typescript", image: typescript },
    { name: "JQuery", image: jquery },
    { name: "Greensock", image: greensock },
    { name: "Cypress", image: cypressWhite },
    { name: "Jest", image: jest },
  ],
  backend: [
    { name: "Node.js", image: node },
    { name: "Express", image: express },
    { name: "SQL", image: sql },
    { name: "phpMyAdmin", image: phpMyAdmin },
    { name: "MongoDB", image: mongo },
  ],
  devops: [
    { name: "Docker", image: docker },
    { name: "Kubernetes", image: kubernetes },
    { name: "Heroku", image: heroku },
    { name: "Netlify", image: netlify },
    { name: "cPanel", image: cPanel },
  ],
  other: [
    { name: "Figma", image: figma },
    { name: "Microsoft Office", image: office },
    { name: "VSCode", image: vscode },
    { name: "Photoshop", image: photoshop },
    { name: "Lightoom", image: lightroom },
    { name: "npm", image: npm },
    { name: "Insomnia", image: insomnia },
    { name: "Slack", image: slack },
    { name: "zoom", image: zoom },
    { name: "GitHub", image: githubLight },
  ],
};
