import React, { useEffect } from "react";
import Header from "../Header";
import gsap from "gsap";
import TextPlugin from "gsap/TextPlugin";
import { aboutData } from "./aboutData";

function AboutPage() {
  gsap.registerPlugin(TextPlugin);

  useEffect(() => {
    gsap.to(".about--text", {
      duration: 10,
      delay: 3,
      text: aboutData.profile,
      ease: "none",
    });
  }, []);

  return (
    <div className="about">
      <Header />
      <div className="about--container">
        <h1>Actively seeking Junior React developer roles.</h1>
        <h2>Hi there! My name is Samuel Sheppard...</h2>
        <p className="about--text"></p>
      </div>
    </div>
  );
}

export default AboutPage;
