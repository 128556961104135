import React from "react";

function SkillsItem(props) {
  const { name, image } = props;

  return (
    <div className="skills--item">
      <p>{name}</p>
      <img src={image} alt={name} />
    </div>
  );
}

export default SkillsItem;
