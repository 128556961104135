import React from "react";
import { Link } from "react-router-dom";
import cv from "../assets/samuel_sheppard_cv.pdf";
function Nav() {
  return (
    <div className="header--nav">
      <Link to="/">about</Link>
      <Link to="/projects">projects</Link>
      <Link to="/skills">skills</Link>
      <Link to="/contact">contact</Link>
      <a href={cv} target="_blank" rel="noreferrer">
        cv
      </a>
    </div>
  );
}

export default Nav;
