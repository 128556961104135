import React from "react";
import AboutPage from "./components/AboutPage/AboutPage";
import ProjectsPage from "./components/ProjectsPage/ProjectsPage";
import SkillsPage from "./components/SkillsPage/SkillsPage";
import ContactPage from "./components/ContactPage/ContactPage";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./styles/styles.css";

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<AboutPage />} />
        <Route exact path="/projects" element={<ProjectsPage />} />
        <Route exact path="/skills" element={<SkillsPage />} />
        <Route exact path="/contact" element={<ContactPage />} />
      </Routes>
    </Router>
  );
}

export default App;
