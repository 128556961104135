import React from "react";
import Nav from "./Nav";
import { Link } from "react-router-dom";

function Header() {
  return (
    <div className="header">
      <Link to="/">&#123;SS&#125;</Link>
      <Nav />
    </div>
  );
}

export default Header;
