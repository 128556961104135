import React, { useEffect } from "react";
import SkillsItem from "./SkillsItem";
import gsap from "gsap";

function SkillsSection(props) {
  const { title, skills } = props;

  const itemMap = () => {
    return skills.map((item, index) => {
      return <SkillsItem name={item.name} image={item.image} key={index} />;
    });
  };

  useEffect(() => {
    const enter = gsap.fromTo(
      ".skills--section",
      { y: -50, opacity: 0 },
      { y: 0, opacity: 1, duration: 2, ease: "sine", delay: 1.5 }
    );
    enter.play();
  }, []);

  return (
    <div className="skills--section">
      <h1>{title}</h1>
      <div className="skills-section--item--container">{itemMap()}</div>
    </div>
  );
}

export default SkillsSection;
