import newsHub from "../../assets/project-screenshots/newsHub.png";
import kwizApp from "../../assets/project-screenshots/kwizApp.png";
import whatToCook from "../../assets/project-screenshots/whatToCook.png";
import simpsonsApi from "../../assets/project-screenshots/simpsonsApi.png";
import fanoGuitars from "../../assets/project-screenshots/fanoGuitars.png";

export const projectsData = [
  {
    title: "News Hub",
    type: "Fullstack",
    tech: [
      "React",
      "Redux",
      "Hooks",
      "Javascript(ES6+)",
      "Nodejs",
      "Express",
      "MySQL",
      "RESTful",
      "SASS",
      "Greensock",
    ],
    image: newsHub,
    link: "https://news-hub.co.uk",
    description:
      "News Hub provides the user with top headlines and articles from a wide selection of sources UK based news sources. The user can create an account and save their news source preferences. Backend repo available upon request.",
    github: "https://github.com/samuelasheppard/news-hub",
  },
  {
    title: "Kwiz App",
    type: "React - Frontend",
    tech: ["React", "Hooks", "Javascript(ES6+)", "RESTful", "CSS"],
    image: kwizApp,
    link: "https://kwiz-app.netlify.app",
    description:
      "The Kwiz App is a fun trivia game with 25 different categories and 5 different game lengths. The users highscore is saved in local storage. Made using React Hooks and RESTful api.",
    github: "https://github.com/samuelasheppard/kwiz-app",
  },
  {
    title: "What to cook",
    type: "Vanilla - Frontend",
    tech: ["Javascript(ES6+)", "RESTful", "HTML 5", "CSS"],
    image: whatToCook,
    link: "https://what-to-cook-recipes.netlify.app/",
    description:
      "What To Cook is a recipe discovery website. Users can input the ingredients they have and What To Cook will search for all the recipes they can make. Users can also search for a random recipe or request a random food related joke. Made using vanilla Javascript and RESTful apis.",
    github: "https://github.com/samuelasheppard/what-to-cook",
  },
  {
    title: "Fano Guitars website Recreation",
    type: "CSS - Frontend",
    tech: ["HTML", "CSS"],
    image: fanoGuitars,
    link: "https://fano-guitars-recreation.netlify.app/",
    description:
      "This is a homage to Fano Guitars website using plain CSS and HTML. This site has recreation of: home page, dealers page and contact page. I also had a go at creating a more interactive and cleaner looking dealers page.",
    github: "https://github.com/samuelasheppard/fano-recreation-repo",
  },
  {
    title: "Simpsons api",
    type: "React - Frontend",
    tech: ["React", "Hooks", "Javascript(ES6+)", "RESTful", "CSS"],
    image: simpsonsApi,
    link: "https://simpsons-api.netlify.app/",
    description:
      "My first React project. A simple interface to look through random Simpson's characters quotes. Users can filter by name and are able to delete quotes.",
    github: "https://github.com/samuelasheppard/simpsons-api",
  },
];
