import React from "react";
import Header from "../Header";
import { skillsData } from "./skillsData";
import SkillsSection from "./SkillsSection";

function SkillsPage() {
  const { frontend, backend, devops, other } = skillsData;
  return (
    <div className="skills">
      <Header />
      <div className="skills--container">
        <SkillsSection title="Frontend" skills={frontend} />
        <SkillsSection title="Backend" skills={backend} />
        <SkillsSection title="DevOps" skills={devops} />
        <SkillsSection title="Other" skills={other} />
      </div>
    </div>
  );
}

export default SkillsPage;
