import React, { useState, useEffect } from "react";
import Header from "../Header";
import chevronRight from "../../assets/chevron-right.png";
import chevronLeft from "../../assets/chevron-left.png";
import { projectsData } from "./projectsData";
import gsap from "gsap";
import git from "../../assets/github.png";

function ProjectsPage() {
  const [pageNum, setPageNum] = useState(0);

  const projectMap = () => {
    const { title, type, tech, image, link, description, github } =
      projectsData[pageNum];
    return (
      <div className="projects--carousel--item">
        <div className="left">
          <h2>{title}</h2>
          <h3>{type}</h3>
          <img
            src={image}
            alt={title}
            onClick={() => {
              window.open(link);
            }}
          />
        </div>
        <div className="right">
          <p className="tech">{tech.join(" | ")}</p>
          <p>{description}</p>
          <a href={github} target="_blank" rel="noreferrer">
            <img src={git} alt="github repo" />
          </a>
        </div>
      </div>
    );
  };
  useEffect(() => {
    gsap.fromTo(
      ".projects--carousel--item",
      { ease: "none", opacity: 0 },
      { duration: 3, opacity: 1, delay: 1 }
    );
  }, []);

  const cycleLeft = () => {
    const animateLeft = gsap.fromTo(
      ".projects--carousel--item",
      { x: -150, ease: "sine", opacity: 0 },
      { x: 0, duration: 3, opacity: 1 }
    );
    const prev = pageNum - 1;
    if (prev === -1) {
      setPageNum(projectsData.length - 1);
    } else {
      setPageNum(prev);
    }
    animateLeft.play();
  };

  const cycleRight = () => {
    const animateRight = gsap.fromTo(
      ".projects--carousel--item",
      { x: 150, ease: "sine", opacity: 0 },
      { x: 0, duration: 3, opacity: 1 }
    );
    const next = pageNum + 1;
    if (next < projectsData.length) {
      setPageNum(next);
    } else {
      setPageNum(0);
    }
    animateRight.play();
  };

  return (
    <div className="projects">
      <Header />
      <div className="projects--carousel--container">
        <div className="projects--carousel">
          <img
            src={chevronLeft}
            alt="cycle left"
            onClick={() => {
              cycleLeft();
            }}
          />
          {projectMap()}
          <img
            src={chevronRight}
            alt="cycle right"
            onClick={() => {
              cycleRight();
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default ProjectsPage;
