import React from "react";
import Header from "../Header";
import email from "../../assets/email.png";
import github from "../../assets/github.png";
import linkedin from "../../assets/linkedin.png";

function ContactPage() {
  return (
    <div className="contact">
      <Header />
      <div className="contact--container">
        <h2>Please feel free to get in touch...</h2>
        <div className="contact--links--container">
          <a href="mailto:samuelasheppard@gmail.com">
            <img src={email} alt="email" />
          </a>
          <a
            href="https://github.com/samuelasheppard"
            target="_blank"
            rel="noreferrer"
          >
            <img src={github} alt="github" />
          </a>
          <a
            href="https://www.linkedin.com/in/samuelasheppard/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={linkedin} alt="linkedin" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default ContactPage;
